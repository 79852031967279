export interface Display {
    device_name: string;
    device_description?: string;
    id: string;
    initial_url: string;
    zoom_level: number;
    auto_refresh_enabled: boolean;
    keep_foreground: boolean;
    can_focus: boolean;
    auto_refresh_interval_seconds: number;
    secondary_url: string;
    enable_schedule: boolean;
    require_unlock: boolean;
    unlock_code: string;
    is_in_trial: boolean;
    trial_expires_at?: Date;
    online_management_expires_at?: Date;
    is_full_activation: boolean;
    fully_activated_at?: Date;

    day_1_start?: string;
    day_1_end?: string;
    day_2_start?: string;
    day_2_end?: string;
    day_3_start?: string;
    day_3_end?: string;
    day_4_start?: string;
    day_4_end?: string;
    day_5_start?: string;
    day_5_end?: string;
    day_6_start?: string;
    day_6_end?: string;
    day_7_start?: string;
    day_7_end?: string;
}

export function isOnlineManagementExpired(display: Display): boolean {
    if (display.is_full_activation) {
        // Device on an active subscription
        if (!display.online_management_expires_at) {
            return false; // No expiration date means it doesn't expire.
        }

        // Convert Timestamp to JavaScript Date object
        // Assuming online_management_expires_at is a Timestamp
        // If it's already a Date object, you don't need conversion.
        const expiryDate = new Date(display.online_management_expires_at.seconds * 1000);

        const now = new Date(); // Current date and time
        if (expiryDate > now) {
            return false; // Subscription is still valid
        }

        return true; // Subscription has expired
    }

    // Assuming trial or no subscription
    return false; // Default return for devices without full activation
}