import React, { useState, useEffect, useMemo } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions, db } from '../../firebase/config';
import { collection, addDoc, query, where, onSnapshot, getDoc, doc } from 'firebase/firestore';
import { Box, Button, Slide, Container, TextField, Typography, CircularProgress, Grid, List, ListItem, ListItemText, Alert, ListItemAvatar, Avatar, Divider } from '@mui/material';
import DeviceSettingsDialog from '../../components/deviceSettings/deviceSettingsDialog';
import { Display, isOnlineManagementExpired } from '../../state/models/display';
import toggleBodyScroll from '../../utils/toggleBodyScroll';
import { ArrowForward, ConnectedTv, Edit, PlayForWork, Settings } from '@mui/icons-material';
import { HeadFC } from 'gatsby';
import LayoutType from '../../constants/layout-type';
import Colors from '../../constants/colors';
import DeviceConnectDialog from '../../components/deviceConnect/deviceConnectDialog';
import MotionBox from '../../components/motion/motionBox';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeviceUpgradeDialog from '../../components/deviceUpgrade/deviceUpgradeDialog';
import { useUser } from '../../contexts/UserContext';

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardPage: React.FC = () => {
    const [displays, setDisplays] = useState<Display[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [displayCode, setDisplayCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [selectedDisplay, setSelectedDisplay] = useState<Display | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
    const [animateTopSection, setAnimateTopSection] = useState<boolean>(true);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [isListAnimated, setIsListAnimated] = useState<boolean>(false);
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState<boolean>(false);

    const { user } = useUser();

    useEffect(() => {
        if (!loading && isFirstLoad) {
            setIsFirstLoad(false);
            setIsListAnimated(true); 
        }
    }, [loading]);

    const handleOpenUpgradeDialog = (display: Display) => {
        setSelectedDisplay(display);
        setIsUpgradeDialogOpen(true);
    };

    const handleCloseUpgradeDialog = () => {
        setIsUpgradeDialogOpen(false);
    };

    const handleUpgrade = async (planDetails: any) => {
        // Implement your upgrade logic here, possibly using a Firebase function
        console.log('Upgrading to plan:', planDetails);
        // Close the dialog after processing the upgrade
        setIsUpgradeDialogOpen(false);
    };

    const handleEditSettings = (display: Display) => {
        setSelectedDisplay(display);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedDisplay(null);
    };

    const handleOpenConnectDeviceDialog = () => {
        setIsConnectDialogOpen(true);
    };

    const handleCloseConnectDeviceDialog = () => {
        setIsConnectDialogOpen(false);
        setSelectedDisplay(null);
    };

    const handleSaveSettings = async (deviceId: string, updatedSettings: any) => {
        const updateSettingsFn = httpsCallable(functions, 'updateSettingsFromWeb');
        await updateSettingsFn({ deviceId, settings: updatedSettings });
        setIsDialogOpen(false); // Close the dialog after saving
        fetchDisplays(); // Refresh the list of displays
    };

    const fetchDisplays = (): (() => void) => {
        setLoading(true);
        const userId = user?.uid;
        if (!userId) {
            return () => { };
        }

        const listUserDevicesFn = httpsCallable(functions, 'listUserDevices');
        listUserDevicesFn().then(result => {
            const deviceIds = result.data.devices || [];
            let unsubscribes: (() => void)[] = [];
            let initialDataReceived = 0; 

            // Clear existing displays before setting new ones from snapshot
            setDisplays([]);
            if (deviceIds.length === 0) {
                setLoading(false);
            }

            deviceIds.forEach(deviceId => {
                const deviceRef = doc(db, 'device_settings', deviceId);
                const unsubscribe = onSnapshot(deviceRef, docSnap => {
                    if (docSnap.exists()) {
                        // Use functional update to ensure we always have the latest state
                        setDisplays(prevDisplays => {
                            const index = prevDisplays.findIndex(display => display.id === deviceId);
                            const updatedDisplay = { ...docSnap.data(), id: docSnap.id };
                            // If the device already exists, update it; otherwise, add it
                            if (index >= 0) {
                                const newDisplays = [...prevDisplays];
                                newDisplays[index] = updatedDisplay;
                                return newDisplays;
                            } else {
                                return [...prevDisplays, updatedDisplay];
                            }
                        });
                    }
                    // Increment counter and check if all initial data has been received
                    initialDataReceived++;
                    if (initialDataReceived === deviceIds.length) {
                        setLoading(false); // Only set loading to false after all devices have been processed
                    }
                }, error => {
                    console.error("Error fetching device data:", error);
                });
                unsubscribes.push(unsubscribe);
            });

            // Return cleanup function
            return () => unsubscribes.forEach(unsubscribe => unsubscribe());
        }).catch(error => {
            console.error("Error listing user devices:", error);
            setError(error.message);
            setLoading(false);
        });

        return () => { };
    };

    useEffect(() => {
        if (!user?.uid) {
            return;
        }

        setError('');
        setIsConnectDialogOpen(false);
        setIsDialogOpen(false);
        setSelectedDisplay(null);
        setIsUpgradeDialogOpen(false);
        const unsubscribe = fetchDisplays();
        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        if (typeof window === 'undefined')
            return;

        toggleBodyScroll(isDialogOpen || isConnectDialogOpen || isUpgradeDialogOpen);

        return () => {
            toggleBodyScroll(false);
        };
    }, [isDialogOpen, isConnectDialogOpen, isUpgradeDialogOpen]);


    const handleConnectDisplayFromDialog = async (displayConnectionInformation: any, onSuccess: () => void, onError: (errorMessage: string) => void) => {
        if (!displayConnectionInformation.code) {
            onError('Please enter the display code from the Screen Keep settings section on your device.');
            return;
        }
        setLoading(true);
        setError('');
        const connectDisplay = httpsCallable(functions, 'linkDeviceToUser');
        connectDisplay(displayConnectionInformation)
            .then((result) => {
                // Refresh the list of displays after connecting a new one
                setIsConnectDialogOpen(false);
                fetchDisplays();
                setDisplayCode('');
                onSuccess();
            })
            .catch((error) => {
                onError('Error connecting display. Display codes are valid for 15 minutes. Please enter a new code and try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: displays.length > 0 || !loading ? 1 : 0, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: displays.length > 0 || !loading ? 'visible' : 'hidden', background: Colors.BACKGROUND_DARK }
    }), [displays.length, loading]);

    const secondBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: {
            visibility: displays.length > 0 || !loading ? 'visible' : 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            overflow: 'hidden',
        }
    }), [displays.length, loading]);



    return (
        <>
            <MotionBox
                {...firstBoxAnimationProps}
            >
                <Container maxWidth="md">
                    <Box textAlign="center" pt={{ xs: 8, sm: 10, md: 13 }} pb={{ xs: 2, sm: 3, md: 4 }} mb={{ xs: 1, sm: 2, md: 3 }} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                        <Typography component="h1" variant="h1" sx={{ mt: { xs: 2, sm: 3, md: 4 }, mb: 0, maxWidth: "100%", fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
                            {loading || displays.length > 0 ? 'Screen Management' : 'Welcome To Screen Keep'}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: { xs: 1, sm: 2, md: 4 }, mt: { xs: -2, sm: -1, md: 0 }, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}>
                            {loading || displays.length > 0 ? 'Manage your registered screens.' : 'Get Started By Registering Your First Screen'}
                        </Typography>
                    </Box>
                </Container>
            </MotionBox>
            <Container component="main" maxWidth="lg">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    {loading ? (
                        <>
                            <Box mb={8} mt={4}><Typography variant="h4">Loading The Screen Keep Dashboard</Typography></Box>
                            
                            <Box mb={30}><CircularProgress size={70} /></Box>
                        </>
                    ) : (
                        <MotionBox
                            {...secondBoxAnimationProps}
                            >
                                <List sx={{ maxWidth: '100%', width: '100%' }}>
                                    {displays
                                        .map((display) => {
                                            const onlineManagementExpired = isOnlineManagementExpired(display);
                                        return (
                                            <React.Fragment key={display.id}>
                                                <ListItem
                                                    sx={{
                                                        overflow: 'hidden',
                                                        pb: 3,
                                                        pt: 3,
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        flexWrap: { xs: 'wrap', md: 'nowrap' }
                                                    }}
                                                >
                                                    <ListItemAvatar sx={{ minWidth: '56px', display: 'flex', alignItems: 'center' }}>
                                                        <Avatar>
                                                            <ConnectedTv />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        sx={{
                                                            pl: 2,
                                                            pr: 2,
                                                            minWidth: 0,
                                                            flexGrow: 1,
                                                            mb: { xs: 2, md: 0 },
                                                            textAlign: { xs: 'center', md: 'left' },
                                                            marginLeft: { xs: '-56px', md: 0 }
                                                        }}
                                                        primary={<Typography variant="h6" sx={{ fontSize: { xs: '1rem', md: '20px' }, mb: 1, mt: 1 }}>{display.device_name}</Typography>}
                                                        secondary={display.device_description}
                                                    />
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: { xs: 'column', md: 'row' },
                                                            width: { xs: '100%', md: 'auto' },
                                                            mt: { xs: 2, md: 0 },
                                                            justifyContent: { md: 'flex-end' }
                                                        }}
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => handleEditSettings(display)}
                                                            sx={{ width: '100%', mb: { xs: 1, md: 0 }, mr: { xs: 0, md: 1 } }}
                                                            endIcon={<Settings />}
                                                        >
                                                            Manage
                                                        </Button>
                                                        {(!display.is_full_activation || onlineManagementExpired) && <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleOpenUpgradeDialog(display)}
                                                            sx={{ width: '100%' }}
                                                            endIcon={<UpgradeIcon />}
                                                        >
                                                            Upgrade
                                                        </Button>}
                                                    </Box>
                                                </ListItem>
                                                {display.is_in_trial && (display.trial_expires_at?.toDate() ?? new Date()) > new Date() &&
                                                    <Alert severity="info" sx={{ mt: 1, mb: 2 }}>This display is in trial mode until {display.trial_expires_at?.toDate().toDateString()}. Upgrade to a paid plan by this date to avoid service interruption.</Alert>
                                                }
                                                {display.is_in_trial && (display.trial_expires_at?.toDate() ?? new Date()) < new Date() &&
                                                    <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>This display is in trial mode and has expired. Upgrade to a paid plan to continue.</Alert>
                                                }
                                                <Divider variant="inset" component="li" />
                                            </React.Fragment>
                                        )
                                        }
                                    )}
                                </List>


                            {displays.length === 0 && !loading &&
                                <Box maxWidth="sm">
                                    <ConnectedTv sx={{ color: Colors.PRIMARY, mt: 4, fontSize: 150 }} />
                                    <Typography>To register your first screen, double press your remote's back button, choose "Register Device" from the Screen Keep app settings screen, then enter the provided code in the box below.</Typography>
                                </Box>
                            }

                            <Button
                                variant="contained"
                                onClick={handleOpenConnectDeviceDialog}
                                disabled={loading}
                                sx={{ mb: 2, mt: 8, width: 353 }}
                                endIcon={<ArrowForward />}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Connect A New Display'}
                            </Button>
                            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                            <Divider sx={{ mb: 3, mt: 2 }}>OR</Divider>
                                <Typography variant="body1" sx={{ mb: 2 }}>{displays.length === 0 ? "Don't yet have Screen Keep installed on your device?" : "Want Screen Keep on another device?"}</Typography>
                            <Button
                                variant="outlined"
                                href="https://play.google.com/store/apps/details?id=com.screenkeep.screenkeep"
                                target="_blank"
                                disabled={loading}
                                sx={{ mb: 2 }}
                                startIcon={<PlayForWork />}
                             >
                                Install Screen Keep On A New Device
                            </Button>
                            <Box sx={{ mb: 5 }}></Box>
                        </MotionBox>
                    )}
                    
                </Box>
            </Container>

            <DeviceSettingsDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                display={selectedDisplay}
                onSave={handleSaveSettings}
                DialogProps={{ TransitionComponent: TransitionSlide }}
                onCloseWithRefresh={() => {
                    handleCloseDialog(); 
                    fetchDisplays();
                }}
            />

            <DeviceConnectDialog
                open={isConnectDialogOpen}
                onClose={handleCloseConnectDeviceDialog}
                onSave={handleConnectDisplayFromDialog}
                DialogProps={{ TransitionComponent: TransitionSlide }}
            />

            <DeviceUpgradeDialog
                open={isUpgradeDialogOpen}
                onClose={handleCloseUpgradeDialog}
                onUpgrade={handleUpgrade}
                device={selectedDisplay}
                DialogProps={{ TransitionComponent: TransitionSlide }}
            />
        </>
    );
};


DashboardPage.layoutType = LayoutType.DefaultFullWidth;

export default DashboardPage;


export const Head: HeadFC = () => <title>ScreenKeep - Digital Signage Devices</title>