import { Dialog, Tab, Tabs, DialogTitle, DialogContent, DialogActions, TextField, Button, Checkbox, FormControlLabel, IconButton, Box, InputAdornment, Typography, useMediaQuery, useTheme, CircularProgress, Divider, FormHelperText, Alert, Grid, DialogContentText, DialogProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Display from '../../state/models/display';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../../constants/colors';
import TimeInput from '../timeInput/timeInput';
import { LinkOffOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';


interface DeviceSettingsDialogProps {
    open: boolean;
    onClose: () => void;
    display: Display | null;
    onSave: (deviceId: string, settings: any) => void;
    onCloseWithRefresh: () => void;
    DialogProps?: DialogProps;
}

const DeviceSettingsDialog: React.FC<DeviceSettingsDialogProps> = ({ open, onClose, display, onSave, onCloseWithRefresh, DialogProps }) => {
    const [initialized, setInitialized] = useState(false);
    const [settings, setSettings] = useState<any>({});
    const [showSecondaryUrl, setShowSecondaryUrl] = useState(false);
    const [autoRefreshTime, setAutoRefreshTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [isSaving, setIsSaving] = useState(false);
    const [showUnlockCode, setShowUnlockCode] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [confirmDeletion, setConfirmDeletion] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [unlinkDeviceError, setUnlinkDeviceError] = useState('');
    const [isUnlinking, setIsUnlinking] = useState(false);

    const handleTabChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };

    const handleUnlinkDevice = () => {
        setConfirmDeletion('');
        setShowConfirmDialog(true);
    }


    const unlinkDevice = async (display: Display) => {
        const disconnectDisplay = httpsCallable(functions, 'unlinkDeviceFromUser');
        return disconnectDisplay({
            device_id: display.id
        });
    };

    const confirmAndUnlinkDevice = () => {
        if (confirmDeletion?.toLowerCase() === display?.device_name?.toLowerCase()) {
            // Call your function to unlink the device here
            // Hide the confirmation dialog
            setShowConfirmDialog(false);
            setUnlinkDeviceError('');

            setIsUnlinking(true);
            unlinkDevice(display)
                .then(() => {
                    setIsUnlinking(false);
                    onCloseWithRefresh();
                })
                .catch((errorMessage: string) => {
                    setUnlinkDeviceError(errorMessage);
                })
                .finally(() => {
                    setIsUnlinking(false);
                });
        } else {
            // Handle the case where the names do not match
            // For example, show an error message
            setUnlinkDeviceError('The device name does not match. Please enter the correct device name to confirm deletion.');
        }
    };

    const handleTransferSubscription = () => {
        // Implement transfer subscription logic here
    }

    const handleUnlinkSubscription = () => {
        // Implement unlink subscription logic here
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (open && display) {
            const totalSeconds = display.auto_refresh_interval_seconds || 0;
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            setTabValue(0);

            setSettings({
                device_name: display.device_name || '',
                device_description: display.device_description || '',
                initial_url: display.initial_url || '',
                zoom_level: display.zoom_level || 1, // Assuming a default value if none provided
                auto_refresh_enabled: display.auto_refresh_enabled || false,
                keep_foreground: display.keep_foreground || false,
                can_focus: display.can_focus || false,
                auto_refresh_interval_seconds: display.auto_refresh_interval_seconds || 0,
                secondary_url: display.secondary_url || '',
                enable_schedule: display.enable_schedule || false,
                require_unlock: display.require_unlock || false,
                unlock_code: display.unlock_code || '',
                day_1_start: display.day_1_start || '',
                day_1_end: display.day_1_end || '',
                day_2_start: display.day_2_start || '',
                day_2_end: display.day_2_end || '',
                day_3_start: display.day_3_start || '',
                day_3_end: display.day_3_end || '',
                day_4_start: display.day_4_start || '',
                day_4_end: display.day_4_end || '',
                day_5_start: display.day_5_start || '',
                day_5_end: display.day_5_end || '',
                day_6_start: display.day_6_start || '',
                day_6_end: display.day_6_end || '',
                day_7_start: display.day_7_start || '',
                day_7_end: display.day_7_end || ''
            });

            setAutoRefreshTime({ hours, minutes, seconds });
            setShowSecondaryUrl(!!display.secondary_url);
            setInitialized(true);
        }
    }, [open, display]);

    // Update auto refresh time state
    const handleAutoRefreshTimeChange = (field) => (e) => {
        const timeValue = Math.max(0, parseInt(e.target.value, 10) || 0);
        setAutoRefreshTime((prev) => ({ ...prev, [field]: timeValue }));
    };

    // Convert auto refresh time to seconds before saving
    const prepareSettingsForSave = () => {
        const { hours, minutes, seconds } = autoRefreshTime;
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;

        return {
            ...settings,
            auto_refresh_interval_seconds: totalSeconds,
        };
    };

    const handleSave = async () => {
        const preparedSettings = prepareSettingsForSave();
        const { zoom_level, auto_refresh_interval_seconds, auto_refresh_enabled } = preparedSettings;
        // Basic validation similar to your Kotlin function
        if (preparedSettings.initial_url && zoom_level > 0 && zoom_level <= 1000 &&
            (!preparedSettings.enable_schedule || preparedSettings.secondary_url) &&
            (!auto_refresh_enabled || auto_refresh_interval_seconds > 0)) {

            setIsSaving(true);

            try {
                // Await the onSave to complete
                await onSave(display?.id || '', preparedSettings);
                // If save is successful, perform any post-save actions here
                onClose(); // Close the dialog if needed, or refresh data
            } catch (error) {
                // Handle any errors that occur during save
                console.error('Error saving display settings:', error);
                // Optionally update state to show an error message
            }

            setIsSaving(false);
        } else {
            alert('Please fill in all required fields with valid values.');
            // You can replace this alert with a more sophisticated feedback mechanism
        }
    };

    if (!display) return null;

    const trialExpired = display.is_in_trial && (display.trial_expires_at?.toDate() ?? new Date()) < new Date;
    const formDisabled = isSaving || trialExpired;
    const hideFormActions = trialExpired;
    
    return (<>
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={fullScreen}
            TransitionComponent={DialogProps?.TransitionComponent}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    handleSave();
                }
            }}
        >
            <DialogTitle sx={{ pt: 4, pb: 3, mb: 0 }} bgcolor={Colors.PRIMARY} color={Colors.BACKGROUND} typography="h5">
                Manage Display Settings
                <IconButton aria-label="close" onClick={onClose} sx={{ mt: 2, mr: 2 }} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon sx={{fill:Colors.BACKGROUND}} />
                </IconButton>
            </DialogTitle>
            <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tab label="Device Settings" />
                <Tab label="Account Options" />
            </Tabs>
            <DialogContent dividers={true} sx={{ overflow: 'auto' }}>
                {tabValue === 1 && (<>
                    {trialExpired &&
                        <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>This display is in trial mode and has expired. Close this dialog and upgrade to a paid plan to continue.</Alert>
                    }
                    {display.is_in_trial && (display.trial_expires_at?.toDate() ?? new Date()) > new Date() &&
                        <Alert severity="info" sx={{ mt: 1, mb: 2 }}>This display is in trial mode until {display.trial_expires_at?.toDate().toDateString()}. Upgrade to a paid plan by this date to avoid service interruption.</Alert>
                    }

                    <Grid container spacing={{xs: 1, sm: 2}} sx={{ flexDirection: { xs: "column", sm: "row" }}}>
                        <Grid item xs={6} md={3}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Device Name:</Typography>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <Typography variant="subtitle1">{display.device_name}</Typography>
                        </Grid>
                        {display.device_description && <>
                            <Grid item xs={6} md={3}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Device Description:</Typography>
                            </Grid>
                            <Grid item xs={6} md={9}>
                                <Typography variant="subtitle1">{display.device_description}</Typography>
                            </Grid>
                        </>}
                        <Grid item xs={6} md={3}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Primary URL:</Typography>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <Typography variant="subtitle1" sx={{ overflow: 'hidden', pr: 5, textOverflow: 'ellipsis', wordBreak: 'keep-all', whiteSpace: 'nowrap', maxWidth: "100%" }}>{display.initial_url}</Typography>
                        </Grid>
                    </Grid>


                    <Divider sx={{ mt: 2, mb: 4 }} />

                    <Box sx={{textAlign: 'center'}}>
                        <Typography variant="h6">Device Account Options</Typography>
                        <Alert severity="warning">
                            Unlinking the device from your account will remove it from your account and deactivate it if you are subscribed to a subscription plan. You will need to re-activate the device to use it again.
                        </Alert>
                        <LoadingButton
                            variant="contained"
                            color="error"
                            onClick={handleUnlinkDevice}
                            sx={{ mt: 2, mb: 2, width: "100%" }}
                            startIcon={<LinkOffOutlined />}
                            loading={isUnlinking}
                        >
                            Unlink Device From Account
                        </LoadingButton>
                        {/*<Typography variant="h6">Subscription Management</Typography>*/}
                        {/*<Button onClick={handleTransferSubscription}>Transfer Subscription</Button>*/}
                        {/*<Button onClick={handleUnlinkSubscription}>Unlink from Subscription</Button>*/}
                        {/*<Typography variant="subtitle1">Device Activation Status: {device.is_active}</Typography>*/}
                        {/*<Typography variant="subtitle1">Current Plan: {currentPlan}</Typography>*/}

                    </Box>
                </>)}
                {tabValue === 0 && (<>
                    {trialExpired &&
                        <Alert severity="warning" sx={{ mt: 1, mb: 2 }}> This display is in trial mode and has expired.Close this dialog and upgrade to a paid plan to continue.</Alert>
                    }
                    {isSaving && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2 // Ensure overlay is above other content but below dialog actions
                        }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {initialized && <>
                        <TextField
                            margin="dense"
                            label="Device Name"
                            type="text"
                            fullWidth
                            required
                            variant="outlined"
                            disabled={formDisabled}
                            value={settings.device_name}
                            onChange={e => setSettings((prev: any) => ({ ...prev, device_name: e.target.value }))}
                        />
                        <TextField
                            margin="dense"
                            label="Device Description"
                            type="text"
                            fullWidth
                            variant="outlined"
                            disabled={formDisabled}
                            value={settings.device_description}
                            onChange={e => setSettings((prev: any) => ({ ...prev, device_description: e.target.value }))}
                        />

                        <Divider sx={{ mt: 2, mb: 4 }} />

                        <TextField
                            margin="dense"
                            label="Primary URL"
                            type="text"
                            fullWidth
                            variant="outlined"
                            disabled={formDisabled}
                            value={settings.initial_url}
                            onChange={e => setSettings((prev: any) => ({ ...prev, initial_url: e.target.value }))}
                        />

                        <TextField
                            margin="dense"
                            label="Zoom Percentage"
                            type="number"
                            fullWidth
                            variant="outlined"
                            disabled={formDisabled}
                            value={settings.zoom_level}
                            onChange={e => setSettings((prev: any) => ({ ...prev, zoom_level: parseInt(e.target.value, 10) || 0 }))}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />

                        <Box pb={2} pt={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ ml: 1 }}
                                        checked={settings.auto_refresh_enabled}
                                        onChange={e => {
                                            setSettings((prev) => ({ ...prev, auto_refresh_enabled: e.target.checked }));
                                            if (!e.target.checked) {
                                                setAutoRefreshTime({ hours: 0, minutes: 0, seconds: 0 }); // Reset time when disabled
                                            } else {
                                                setAutoRefreshTime({ hours: 0, minutes: 5, seconds: 0 }); // Set a default interval)
                                            }
                                        }}
                                        disabled={formDisabled}
                                    />
                                }
                                label="Auto Refresh Enabled"
                            />
                            <FormHelperText sx={{ ml: 1 }}>
                                Automatically refreshes the web page at a specified interval.
                            </FormHelperText>
                            {settings.auto_refresh_enabled && (
                                <Box display="flex" alignItems="center" gap={2} sx={{ pt: 2 }}>
                                    <TextField
                                        label="Hours"
                                        type="number"
                                        disabled={formDisabled}
                                        value={autoRefreshTime.hours}
                                        onChange={handleAutoRefreshTimeChange('hours')}
                                    />
                                    <TextField
                                        label="Minutes"
                                        type="number"
                                        disabled={formDisabled}
                                        value={autoRefreshTime.minutes}
                                        onChange={handleAutoRefreshTimeChange('minutes')}
                                    />
                                    <TextField
                                        label="Seconds"
                                        type="number"
                                        disabled={formDisabled}
                                        value={autoRefreshTime.seconds}
                                        onChange={handleAutoRefreshTimeChange('seconds')}
                                    />
                                </Box>
                            )}
                        </Box>

                        <Box pb={2}>
                            <Box pb={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{ ml: 1 }}
                                            disabled={formDisabled}
                                            checked={settings.enable_schedule}
                                            onChange={e => setSettings((prev) => ({ ...prev, enable_schedule: e.target.checked }))}
                                        />
                                    }
                                    label="Enable Secondary Web Page"
                                />
                                <FormHelperText sx={{ ml: 1 }}>
                                    Allows the device to switch between the primary and secondary web pages based on a schedule.
                                </FormHelperText>
                            </Box>

                            {settings.enable_schedule && (
                                <>
                                    <Box display="flex" alignItems="start" gap={2} pt={2}>
                                        <TextField
                                            margin="dense"
                                            label="Secondary URL"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            disabled={formDisabled}
                                            value={settings.secondary_url}
                                            onChange={e => setSettings((prev: any) => ({ ...prev, secondary_url: e.target.value }))}
                                        />
                                        <Box>
                                            <Button type="button"
                                                variant="outlined"
                                                disabled={formDisabled}
                                                onClick={() => setSettings((prev: any) => ({ ...prev, secondary_url: "https://dist.screenkeep.com/night-screen" }))} sx={{ m: 0, mt: 1.5, p: 2, minWidth: '180px' }}>
                                                Screen Off URL
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box pt={3} pb={1} pl={1}>
                                        <Typography variant="h6" color="textSecondary">
                                            Primary URL Display Schedule
                                        </Typography>
                                    </Box>
                                    {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day, index) => (
                                        <Box key={day} display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                                            <TimeInput
                                                label={`${day} Start Time`}
                                                value={settings[`day_${index + 1}_start`] || ''}
                                                disabled={formDisabled}
                                                onChange={(time: string) => setSettings((prev: any) => ({ ...prev, [`day_${index + 1}_start`]: time }))}
                                            />
                                            <TimeInput
                                                label={`${day} End Time`}
                                                value={settings[`day_${index + 1}_end`] || ''}
                                                disabled={formDisabled}
                                                onChange={(time: string) => setSettings((prev: any) => ({ ...prev, [`day_${index + 1}_end`]: time }))}
                                            />
                                        </Box>
                                    ))}
                                </>
                            )}
                        </Box>



                        <Box pb={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ ml: 1 }}
                                        checked={settings.keep_foreground}
                                        disabled={formDisabled}
                                        onChange={e => setSettings((prev: any) => ({ ...prev, keep_foreground: e.target.checked }))}
                                    />
                                }
                                label="Keep Screen Keep In Foreground"
                            />
                            <FormHelperText sx={{ ml: 1 }}>
                                Attempts to restore the Screen Keep app to the foreground if it is sent to the background.
                            </FormHelperText>
                        </Box>


                        <Box pb={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ ml: 1 }}
                                        checked={settings.can_focus}
                                        disabled={formDisabled}
                                        onChange={e => setSettings((prev: any) => ({ ...prev, can_focus: e.target.checked }))}
                                    />
                                }
                                label="Allow Web Page Focus"
                            />
                            <FormHelperText sx={{ ml: 1 }}>
                                Allows the web page to receive focus and respond to keyboard and mouse input.
                            </FormHelperText>
                        </Box>


                        <Box pb={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ ml: 1 }}
                                        checked={settings.require_unlock}
                                        disabled={formDisabled}
                                        onChange={e => setSettings((prev: any) => ({ ...prev, require_unlock: e.target.checked }))}
                                    />
                                }
                                label="Require Settings Access Code"
                            />
                            <FormHelperText sx={{ ml: 1 }}>
                                Requires a code to access or change settings on the device.
                            </FormHelperText>
                            {settings.require_unlock && (
                                <Box display="flex" alignItems="center" gap={2} pt={2}>
                                    <TextField
                                        margin="dense"
                                        label="Unlock Code"
                                        type={showUnlockCode ? 'text' : 'password'} // Change based on the state
                                        fullWidth
                                        variant="outlined"
                                        disabled={formDisabled}
                                        value={settings.unlock_code}
                                        onChange={e => setSettings((prev: any) => ({ ...prev, unlock_code: e.target.value }))}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle unlock code visibility"
                                                        onClick={() => setShowUnlockCode(!showUnlockCode)} // Toggle the state
                                                        edge="end"
                                                    >
                                                        {showUnlockCode ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </>}
                 </>)}
            </DialogContent>
            {!hideFormActions && tabValue === 0 &&
                <DialogActions>
                    <Button type="button" onClick={onClose} disabled={isSaving}>Cancel</Button>
                    <Button type="submit" disabled={isSaving} variant="outlined">Save</Button>
                </DialogActions>
            }
        </Dialog>

        <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    confirmAndUnlinkDevice();
                },
                autoComplete: "off" 
            }}>
            <DialogTitle sx={{ pt: 4, mb: 1, fontSize: 20 }}>
                Confirm Device Unlink
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To unlink the device, please type the device name <strong>{display.device_name}</strong> below to confirm.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Device Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={{ mt:2 }}
                    value={confirmDeletion}
                    onChange={(e) => setConfirmDeletion(e.target.value)}
                />
                {unlinkDeviceError && <Alert severity="error" sx={{ mt: 1, mb: 2 }}>{unlinkDeviceError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" type="button" onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
                <Button variant="outlined" type="submit" color="error">Unlink Device</Button>
            </DialogActions>
        </Dialog>
    </>);
};


export default DeviceSettingsDialog;