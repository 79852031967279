import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    Box,
    Typography,
    Button,
    IconButton,
    useMediaQuery,
    useTheme,
    CircularProgress,
    Alert,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Switch,
    FormControlLabel,
    DialogContent,
    DialogActions,
    CardActions,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../../constants/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ArrowForward } from '@mui/icons-material';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../firebase/config';
import { openBillingPortalWindow } from '../../utils/redirectToBillingPortal';

const DeviceUpgradeDialog = ({ open, onClose, onUpgrade, device, DialogProps }) => {
    const [isUpgrading, setIsUpgrading] = useState(false);
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isAnnual, setIsAnnual] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (open) {
            setError('');
            setIsSuccess(false);
            setIsAnnual(false);
        }
    }, [open]);

    const handleUpgrade = async (planType) => {
        setIsUpgrading(true); // Start the loading process
        setError(''); // Clear any existing errors

        const checkoutManager = openBillingPortalWindow('Checkout Loading', 'Please wait while we direct you to the checkout...')

        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setError('You must be logged in to upgrade.');
                setIsUpgrading(false); // Stop the loading process
                console.log('Closing checkout window due to no auth');
                checkoutManager.closeWindow(); // Close the blank window if there's an error
                return;
            }

            let docRef;
            let priceId;

            if (planType === 'one_time') {
                priceId = "price_1OmtVSK5RY7PVvxsgML4I0kH"; // One-time price ID from Stripe
            } else if (planType === 'annual' || planType === 'monthly') {
                priceId = planType === 'annual' ? 'price_1Omtc8K5RY7PVvxstaJl20qr' : 'price_1Omtc8K5RY7PVvxsil9sssiJ';
            }

            const success_url = window.location.origin + '/app/payment-success/?device-id=' + device.id;
            const cancel_url = window.location.origin + '/app/payment-cancel/';

            // Create a checkout session
            docRef = await addDoc(collection(db, 'customers', currentUser.uid, 'checkout_sessions'), {
                price: priceId,
                success_url: success_url,
                cancel_url: cancel_url,
                allow_promotion_codes: true,
                invoice_creation: true,
                metadata: {
                    deviceId: device.id
                },
                mode: planType === 'one_time' ? "payment" : "subscription"
            });

            const unsubscribe = onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                const data = snap.data();
                console.log(snap);
                console.log(data);
                console.log('snapshot listening');
                if (error) {
                    console.log('Error during upgrade:');
                    setError(`An error occurred: ${error.message}`);
                    setIsUpgrading(false);
                    checkoutManager.closeWindow(); // Close the blank window if there's an error
                    unsubscribe(); // Detach listener
                } else if (url) {
                    checkoutManager.redirectTo(url); 
                    setIsUpgrading(false); // Optionally keep the loading state until redirected
                     // Detach listener
                }

                if (data?.payment_status === 'failed' || data?.payment_status === 'expired') { // Assuming there is a 'status' field to check
                    console.log('failed:');
                    setIsSuccess(false) // Set success status when transaction is complete
                    setIsUpgrading(false);
                    checkoutManager.closeWindow();
                    unsubscribe();
                } else if (data?.payment_status === 'succeeded') { // Assuming there is a 'status' field to check
                    console.log('succeeded:');
                    setIsSuccess(true); // Set success status when transaction is complete
                    setIsUpgrading(false);
                    checkoutManager.closeWindow();
                    onClose();
                    unsubscribe();
                }
            });

        } catch (error) {
            console.error('Error during upgrade:', error);
            setError('Error during upgrade: ' + error.message);
            setIsUpgrading(false);
            checkoutManager.closeWindow();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={fullScreen}
            TransitionComponent={DialogProps?.TransitionComponent}>
            <DialogTitle sx={{ bgcolor: Colors.PRIMARY, color: Colors.BACKGROUND, display: 'flex', justifyContent: 'center', position: 'relative' }} typography="h5">
                Activate Device
                <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon sx={{ fill: Colors.BACKGROUND }} />
                </IconButton>
            </DialogTitle>
            {isUpgrading && (
                <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
            )}
            <DialogContent>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {isSuccess && (
                    <Alert severity="success">
                        <CheckCircleOutlineIcon /> Thank you for your purchase! Enjoy Screen Keep on your device!
                    </Alert>
                )}
                <Grid container spacing={2} justifyContent="center">

                    <Grid item xs={12} md={5} order={{ xs: 1, md: 4 }}>
                        <Card raised sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', background: Colors.BACKGROUND }}>
                            <CardContent>
                                <Box sx={{ textAlign: 'center', mt: 0 }}>
                                    <Typography variant="h6" gutterBottom sx={{ marginTop: '5px' }}>
                                        Online Management
                                    </Typography>
                                    <Typography variant="h2" component="span" sx={{ position: 'relative', fontWeight: 'bold', display: 'inline-block' }}>
                                        <Box component="span" sx={{ position: 'absolute', top: '0px', left: '-15px', fontSize: '1.25rem', fontWeight: 'bold' }}>$</Box>
                                        {isAnnual ? 24 : 3}
                                        <Box component="span" sx={{ fontSize: '1.25rem', fontWeight: 'normal' }}>.{isAnnual ? '95/yr' : '49/mo'}</Box>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ marginTop: '5px' }}>
                                        Subscription
                                    </Typography>
                                </Box>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ backgroundColor: isAnnual ? 'secondary.main' : 'warning.main', color: 'white', px: 2, py: '2px', textAlign: 'center', borderRadius: '5px', width: isAnnual ? '100px' : '180px', display: 'inline-block' }}>
                                        <Typography variant="caption">{isAnnual ? "RECOMMENDED" : "ANNUAL RECOMMENDED"}</Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ textAlign: 'center', mt: 1, mb: 2 }}>
                                    <FormControlLabel
                                        control={<Switch checked={isAnnual} onChange={() => setIsAnnual(!isAnnual)} />}
                                        label={<Typography sx={{ color: isAnnual ? theme.palette.success.main : theme.palette.warning.main }}>
                                            {isAnnual ? 'Saving 40% With Annual' : 'Save 40% With Annual'}
                                        </Typography>}
                                        sx={{ marginLeft: 2 }}
                                        labelPlacement="top"
                                    />
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                                    <Button variant="contained" color="primary" onClick={() => handleUpgrade(isAnnual ? 'annual' : 'monthly')} disabled={isUpgrading} sx={{ minWidth: '75%' }}
                                        endIcon={<ArrowForward />}
                                    >
                                        Choose Plan
                                    </Button>
                                </Box>
                                <List dense>
                                    <ListItem><ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon><ListItemText>Unlimited Online Management</ListItemText></ListItem>
                                    <ListItem><ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon><ListItemText>Transferrable Between Devices</ListItemText></ListItem>
                                    <ListItem><ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon><ListItemText>Maintained Between Reinstallations</ListItemText></ListItem>
                                    {/*<ListItem><ListItemIcon><CancelOutlinedIcon color="error" /></ListItemIcon><ListItemText>Cancel Anytime</ListItemText></ListItem>*/}
                                </List>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'center' }}>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Invisible placeholder for xs screens to maintain grid alignment */}
                    <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }} order={2}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', alignItems: 'center' }}>
                            <Divider orientation="vertical" flexItem />
                        </Box>
                    </Grid>

                    {/* This item will only show on xs screens (small devices). Separate from vertical divider to avoid affecting layout */}
                    <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }} order={3}>
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', mb: 4, mt: -2 }}>
                            <Divider />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5} order={{ xs: 4, md: 1 }}>
                        <Card raised sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', background: Colors.BACKGROUND }}>
                            <CardContent>
                                <Box sx={{ textAlign: 'center', mt: 0 }}>
                                    <Typography variant="h6" gutterBottom sx={{ marginTop: '5px' }}>
                                        On Device Management
                                    </Typography>
                                    <Typography variant="h2" component="span" sx={{ position: 'relative', fontWeight: 'bold', display: 'inline-block' }}>
                                        <Box component="span" sx={{ position: 'absolute', top: '0px', left: '-15px', fontSize: '1.25rem', fontWeight: 'bold' }}>$</Box>
                                        45
                                        <Box component="span" sx={{ fontSize: '1.25rem', fontWeight: 'normal' }}>.00</Box>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ marginTop: '5px' }}>
                                        One-Time Purchase
                                    </Typography>
                                </Box>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ backgroundColor: 'primary.main', color: 'white', px: 2, py: '2px', textAlign: 'center', borderRadius: '5px', width: '100px', display: 'inline-block' }}>
                                        <Typography variant="caption">POPULAR</Typography>
                                    </Box>
                                </Box>


                                <Box sx={{ textAlign: 'center', mt: 1, mb: 2 }}>
                                    <Typography sx={{ color: theme.palette.secondary }}>
                                        Flat Rate
                                    </Typography>

                                    <FormControlLabel
                                        control={<Switch checked={isAnnual} onChange={() => setIsAnnual(!isAnnual)} />}
                                        label={<Typography sx={{ color: isAnnual ? theme.palette.success.main : theme.palette.warning.main }}>
                                            a
                                        </Typography>}
                                        sx={{ marginLeft: 2, visibility: "hidden" }}
                                        labelPlacement="end"
                                    />
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                                    <Button variant="contained" color="primary" onClick={() => handleUpgrade('one_time')} disabled={isUpgrading} sx={{ minWidth: '75%' }}
                                        endIcon={<ArrowForward />}
                                    >
                                        Choose Plan
                                    </Button>
                                </Box>

                                <List dense>
                                    <ListItem><ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon><ListItemText>Manage settings on device for its lifetime</ListItemText></ListItem>
                                    <ListItem><ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon><ListItemText>3 Months of Online Management</ListItemText></ListItem>
                                    <ListItem><ListItemIcon><CancelOutlinedIcon color="error" /></ListItemIcon><ListItemText>Not Transferrable Between Devices</ListItemText></ListItem>
                                    <ListItem><ListItemIcon><CancelOutlinedIcon color="error" /></ListItemIcon><ListItemText>Must be Repurchased if Uninstalled or Cleared</ListItemText></ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DeviceUpgradeDialog;
