import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Checkbox, FormControlLabel, IconButton, Box, InputAdornment, Typography, useMediaQuery, useTheme, CircularProgress, Divider, Alert, DialogProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../../constants/colors';
import { ArrowForward } from '@mui/icons-material';

interface DeviceConnectDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (settings: any, onSuccess: () => void, onError: (errorMessage: string) => void) => void;
    DialogProps?: DialogProps;
}

const DeviceConnectDialog: React.FC<DeviceConnectDialogProps> = ({ open, onClose, onSave, DialogProps }) => {
    const [initialized, setInitialized] = useState(false);
    const [settings, setSettings] = useState<any>({});
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');
     
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (open) {
            setSettings({
                code: '',
                device_name: '',
                device_description: ''
            });
            
            setInitialized(true);
            setError('');
        }
    }, [open]);

    const handleSave = async () => {
        if (settings) {
            setIsSaving(true);

            try {
                // Await the onSave to complete
                await onSave(settings, () => {
                        onClose();
                        setIsSaving(false);
                    },
                    (errorMessage) => {
                        setError('Error connecting display: ' + errorMessage);
                        setIsSaving(false);
                    });
            } catch (error: any) {
                // Handle any errors that occur during save
                setIsSaving(false);
                console.error('Error connecting display:', error);
                setError('Error connecting display: ' + error.message);
            }
        } else {
            setError('Please fill in all required fields with valid values.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={fullScreen}
            TransitionComponent={DialogProps?.TransitionComponent}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    handleSave();
                }
            }}>
            <DialogTitle sx={{ pt: 4, pb: 3, mb: 0 }} bgcolor={Colors.PRIMARY} color={Colors.BACKGROUND} typography="h5">
                Connect a New Display
                <IconButton aria-label="close" onClick={onClose} sx={{ mt: 2, mr: 2 }} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon sx={{ fill: Colors.BACKGROUND }} />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true} sx={{ overflow: 'auto', pt: 0 }}>
                    {isSaving && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2
                    }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {initialized && <>
                        <Box
                            m={2}
                            ml={1}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: 3
                            }}   
                        >
                            <Box><Typography variant="h2">1</Typography></Box>
                            <Box sx={{
                                layout: 'flex',
                                flex: 1
                            }}>
                                <Typography>Retrieve your device display code by double pressing your remote's back button, then choosing "Register Device" from the Screen Keep app settings screen.</Typography>
                            </Box>
                        </Box>
                        <TextField
                            margin="dense"
                            label="Display Code"
                            type="text"
                            fullWidth
                            required
                            autoFocus
                            variant="outlined"
                            value={settings.code}
                            onChange={e => setSettings((prev: any) => ({ ...prev, code: e.target.value }))}
                            helperText="Enter the code displayed on the device to connect"
                        />
                        

                        <Divider sx={{ my: 3 }} />

                        <Box
                            m={2}
                            ml={1}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: 3
                            }}
                        >
                            <Box><Typography variant="h2">2</Typography></Box>
                            <Box sx={{
                                layout: 'flex',
                                flex: 1
                            }}>
                                <Typography>
                                    Enter a name and description for the device to help you identify it later.
                                    This information will be displayed in the Screen Keep app and management dashboard.
                                </Typography>
                            </Box>
                        </Box>

                        <TextField
                            margin="dense"
                            label="Device Name"
                            type="text"
                            fullWidth
                            required
                            variant="outlined"
                            value={settings.device_name}
                            onChange={e => setSettings((prev: any) => ({ ...prev, device_name: e.target.value }))}
                            helperText="Enter a name for the device"
                        />

                        <TextField
                            margin="dense"
                            label="Device Description"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={settings.device_description}
                            onChange={e => setSettings((prev: any) => ({ ...prev, device_description: e.target.value }))}
                            helperText="Enter a description for the device"
                        />
                </>}
            </DialogContent>
            <DialogActions>
                {error && <Alert severity="error" sx={{ m: 1 }}>{error}</Alert>}
                <Button type="submit"
                    disabled={isSaving}
                    variant="outlined"
                    endIcon={<ArrowForward />}
                    sx={{ minWidth: 125 }}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default DeviceConnectDialog;