// TimeInput.tsx
import React from 'react';
import TextField from '@mui/material/TextField';

interface TimeInputProps {
    label: string;
    value: string;
    disabled: boolean;
    onChange: (value: string) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ label, value, disabled, onChange }) => {
    return (
        <>
            <TextField
                label={label}
                type="time"
                value={value}
                disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300,
                }}
            />
        </>
    );
};

export default TimeInput;